import * as React from 'react'
import { Layout } from '../components/layout'
import { WizzardSteps } from '../components/wizzardsteps'
import { Icon } from '../icons/icon'
import { Link } from 'gatsby'

const Wizzard = () => (
  <Layout>
    <div id="start-form" className="bg-goldig-500">
      <div className="flex items-center justify-center pt-4 ">
        <Link to="/">
          <Icon name="logo" className="text-black max-h-8" />
        </Link>
      </div>
      <div className="section p-sm-fluid max-w-3xl">
        <WizzardSteps />
      </div>
    </div>
  </Layout>
)

export default Wizzard
